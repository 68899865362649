import './Week.css';

export default function Week() {
  return <div className="week">
    <WeekHeader />
  </div>
};

function WeekHeader() {
  return <div className="grid grid-cols-7">
    <div>Mon</div>
    <div>Tue</div>
    <div>Wed</div>
    <div>Thu</div>
    <div>Fri</div>
    <div>Sat</div>
    <div>Sun</div>
  </div>
}

/*
  return <div className="grid grid-cols-7">
    <div>1</div>
    <div>2</div>
    <div>3</div>
    <div>4</div>
    <div>5</div>
    <div>6</div>
    <div>7</div>
  </div>
 */
