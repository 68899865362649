import honeycomb from './honeycomb.svg';
// import logo from './logo.svg';

import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Calendar from './Calendar';
import Week from './Week';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="week" element={<Week />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function HomePage() {
  window.location.assign('https://modal.health');
  return <></>
}

function CalendarPage() {
  return <>
    <img src={honeycomb} className="App-logo" alt="logo" />
    <p>
      Edit <code>src/App.tsx</code> and save to reload.
    </p>
    <a
      className="App-link"
      href="https://reactjs.org"
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn React
    </a>
  </>
}

export default App;
