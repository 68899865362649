import honeycomb from './honeycomb.svg';
import './Calendar.css';

type DateWrapper = {
  date: Date,
  past: boolean,
  today: boolean
};

const weekDays = [ 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT' ];

function firstAndLastDisplayDate(today: Date) {
  const first = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  );

  first.setDate(first.getDate() - first.getDay());

  const last = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0
  );

  last.setDate(last.getDate() + (6 - last.getDay()));

  return [ first, last ];
};

function daysBetween(first: Date, second: Date) {
  return Math.round((second.getTime()-first.getTime())/(1000*60*60*24));
};

function createMonthArray(now: Date): DateWrapper[] {
  const [ first, last ] = firstAndLastDisplayDate(now);

  const totalDays = daysBetween(first, last) + 1;

  return Array(totalDays).fill(null).map((_,i) => {
    const tmp = new Date(first);
    tmp.setDate(tmp.getDate() + i);

    const today = now.toDateString() === tmp.toDateString();

    const past = (now.getTime() - tmp.getTime()) > 0;

    return {
      date: tmp,
      past,
      today
    }
  });
}

function DayBody(_dw: DateWrapper) {
  const showEvent = Math.random() > .8;

  return <>
    {showEvent && <Event name="Party Time"/>}
  </>
}

type EventDetails = {
  name: string
}

function Event({ name }: EventDetails) {
  const styleObj = {
    background: 'rgb(140,80,200)',
    marginRight: 10,
    borderRadius: 4
  };

  return <div style={styleObj} className='text-left pl-2 text-white'>{name}</div>
}

function DayHeader({ date, today }: DateWrapper) {
  let classNames = "m-auto mt-1 rounded-full w-7 h-7 text-center text-sm align-middle";
  if (today) classNames += " " + "bg-blue-500 text-white";

  return <div className={classNames} style={{lineHeight: "1.75rem"}}>{date.getDate()}</div>
}

function Day({ date, past, today }: DateWrapper) {
  return <div>
    <DayHeader date={date} past={past} today={today}  />
    <DayBody date={date} past={past} today={today} />
  </div>
}

export default function Calendar() {
  const today = new Date();
  const monthName = today.toLocaleString('default', { month: 'long' });
  const year = today.getFullYear();
  const daysToDisplay = createMonthArray(today);
  const firstWeek: Array<[DateWrapper, string]> = daysToDisplay.splice(0,7).map((e,i) => [e, weekDays[i]]);

  return <>
    <h1>{monthName} - {year}</h1>
    <div className="calendar grid grid-cols-7">
      {firstWeek.map(a => {
        const [dw, weekday] = a;
        return <div>
          <div className="text-gray-500">{weekday}</div>
          <Day {...dw}/>
        </div>
      })}

      {daysToDisplay.map(a => <Day {...a} />)}
    </div>
    <img src={honeycomb} className="App-logo" alt="logo" />
  </>
}
